(function ($) {
    window.storeNumbersRequested = [];
    window.PG_Call = {
        orderby: 'name',
        direction: 'asc',

        init: function () {
            switch (location.pathname) {
                case "/user":
                    PG_Call.getCallSuccessMsg();
                    break;
                case "/user/call":
                    PG_Call.registerGetStoresClickHandler();
                    break;
                // case "/user/call/stores":
                // 	PG_Call.listStores();
                // 	PG_Call.startCall();
                // 	break;
            }
        },

        /**
         * @param {number} distance - distance (km) from the point represented by centerPoint
         * @description
         *   Computes the bounding coordinates of all points on the surface of a sphere
         *   that has a great circle distance to the point represented by the centerPoint
         *   argument that is less or equal to the distance argument.
         *   Technique from: Jan Matuschek <http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates>
         * @author Alex Salisbury
         */
        getBoundingBox: function (lat, lng, distance) {

            'use strict';

            var MIN_LAT, MAX_LAT, MIN_LON, MAX_LON, R, radDist, degLat, degLon, radLat, radLon, minLat, maxLat, minLon,
                maxLon, deltaLon;
            if (distance < 0) {
                return 'Illegal arguments';
            }
            // helper functions (degrees<–>radians)
            Number.prototype.degToRad = function () {
                return this * (Math.PI / 180);
            };
            Number.prototype.radToDeg = function () {
                return (180 * this) / Math.PI;
            };
            // coordinate limits
            MIN_LAT = (-90).degToRad();
            MAX_LAT = (90).degToRad();
            MIN_LON = (-180).degToRad();
            MAX_LON = (180).degToRad();
            // Earth's radius (km)
            R = 6378.1;
            // angular distance in radians on a great circle
            radDist = distance / R;
            // center point coordinates (deg)
            degLat = lat;
            degLon = lng;
            // center point coordinates (rad)
            radLat = degLat.degToRad();
            radLon = degLon.degToRad();
            // minimum and maximum latitudes for given distance
            minLat = radLat - radDist;
            maxLat = radLat + radDist;
            // minimum and maximum longitudes for given distance
            minLon = void 0;
            maxLon = void 0;
            // define deltaLon to help determine min and max longitudes
            deltaLon = Math.asin(Math.sin(radDist) / Math.cos(radLat));
            if (minLat > MIN_LAT && maxLat < MAX_LAT) {
                minLon = radLon - deltaLon;
                maxLon = radLon + deltaLon;
                if (minLon < MIN_LON) {
                    minLon = minLon + 2 * Math.PI;
                }
                if (maxLon > MAX_LON) {
                    maxLon = maxLon - 2 * Math.PI;
                }
            }
            // a pole is within the given distance
            else {
                minLat = Math.max(minLat, MIN_LAT);
                maxLat = Math.min(maxLat, MAX_LAT);
                minLon = MIN_LON;
                maxLon = MAX_LON;
            }

            return {
                isWithin: function (latitude, longitude) {
                    var returnValue = latitude <= maxLat.radToDeg() && latitude >= minLat.radToDeg() && longitude >= minLon.radToDeg() && longitude <= maxLon.radToDeg();
                    if (returnValue) {
                        console.log('Found a store within the bounding box');
                        console.log(
                            this.upperLeft()[0] + ", " + this.upperLeft()[1] + "\n" +
                            this.upperRight()[0] + ", " + this.upperRight()[1] + "\n" +
                            this.lowerRight()[0] + ", " + this.lowerRight()[1] + "\n" +
                            this.lowerLeft()[0] + ", " + this.lowerLeft()[1]
                        )
                    }
                    return returnValue;
                },
                upperRight: function () {
                    return [maxLat.radToDeg(), maxLon.radToDeg()];
                },
                lowerRight: function () {
                    return [minLat.radToDeg(), maxLon.radToDeg()];
                },
                lowerLeft: function () {
                    return [minLat.radToDeg(), minLon.radToDeg()];
                },
                upperLeft: function () {
                    return [maxLat.radToDeg(), minLon.radToDeg()];
                }
            };
        },

        getCallSuccessMsg: function () {
            if (location.search) {
                var msg = location.search.replace("?msg=", "");
                $('.alert').remove();
                var message = '<div class="alert alert-success" role="alert" style="width:50%; margin:0 auto; text-align:center;">' +
                    '		<span class="success-icon glyphicon glyphicon-ok-sign" aria-hidden="true"></span>&nbsp;&nbsp;' + decodeURIComponent(msg) +
                    '</div>';
                $(message).insertBefore('.queue');
            }
        },

        registerGetStoresClickHandler: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                var url = location.href + "/stores/get/";
                AJAXGetRequest(url, function (stores) {
                    if (stores) {
                        PG_Call.getCallerStore(stores);
                    }
                });
            });
        },

        split: function (arr, n) {
            var res = [];
            while (arr.length) {
                res.push(arr.splice(0, n));
            }
            return res;
        },

        getFetchPromises: function (origin, currentBatch) {
            var returnValue = PG_Call.split(currentBatch, 25).reduce(function (storeDetailsFetchPromiseAccumulator, storeInBatchesOfTwentyFive) {
                storeDetailsFetchPromiseAccumulator.push(GoogleDistanceMatrix(origin, storeInBatchesOfTwentyFive.reduce(function (accumulator, store) {
                    accumulator.push(store.latitude + ',' + store.longitude);
                    return accumulator;
                }, [])).then(function (response) {
                    if (response.distanceMatrixStatus === google.maps.DistanceMatrixStatus.OK) {
                        return response.rows[0].elements.map(function (destinationObject, index) {
                            if (destinationObject.status === "ZERO_RESULTS") {
                                storeInBatchesOfTwentyFive[index].distanceInTime = Number.MAX_VALUE;
                                return storeInBatchesOfTwentyFive[index];
                            }
                            storeInBatchesOfTwentyFive[index].distanceInTime = destinationObject.duration.value;
                            return storeInBatchesOfTwentyFive[index];
                        });
                    }
                    console.log("Dropped [" + storeInBatchesOfTwentyFive.length + "] stores from output because of [" + response.distanceMatrixStatus + "]");
                }));
                return storeDetailsFetchPromiseAccumulator;
            }, []);
            return returnValue;
        },

        getBulkRequestPromise: function (stores, origin, allPromises) {
            var bulkRequestSize = 100;
            var currentBatch = stores.slice(0, bulkRequestSize);
            var currentPromise = $.when.apply($, PG_Call.getFetchPromises(origin, currentBatch)).then(function () {
                var deferred = $.Deferred();
                var args = arguments;
                if (stores.length - bulkRequestSize > 0) {
                    setTimeout(function () {
                        deferred.resolve(args);
                    }, 11000); //You have to wait 10 seconds after requesting 100 stores. Seems like an undocumented limit in the google map api.
                } else {
                    deferred.resolve(args);
                }
                return deferred.promise();
            });
            allPromises.push(currentPromise);
            if (stores.slice(bulkRequestSize).length > 0) {
                return currentPromise.then(function () {
                    PG_Call.getBulkRequestPromise(stores.slice(bulkRequestSize), origin, allPromises)
                });
            } else {
                return currentPromise;
            }
        },

        getCallerStore: function (stores) {
            eraseCookie('nearby_stores');
            eraseCookie('caller_lat');
            eraseCookie('caller_lng');
            eraseCookie('imageIds');
            eraseCookie('imageNotes');
            createCookie('imageIds', []);
            $('.loading').show();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {

                    $("#start form input[name=latitude]").val(position.coords.latitude);
                    $("#start form input[name=longitude]").val(position.coords.longitude);
                    createCookie('caller_lat', position.coords.latitude); // Store the caller latitude for the call
                    createCookie('caller_lng', position.coords.longitude); // Store the caller longitude for the call

                    var origin = [position.coords.latitude + ',' + position.coords.longitude];

                    GoogleGeocoder('location', new google.maps.LatLng(position.coords.latitude, position.coords.longitude), function (response, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var state = '';
                            var address_components = response[0].address_components;
                            address_components.forEach(function (component) {
                                if (component.types[0] === 'administrative_area_level_1') {
                                    state = component.short_name;
                                }
                            });
                            var oneMileInKilometers = 1.60934;
                            var boundingBox = PG_Call.getBoundingBox(position.coords.latitude, position.coords.longitude, oneMileInKilometers);
                            var promises = [];
                            PG_Call.getBulkRequestPromise(stores.filter(function (store) {
                                return boundingBox.isWithin(store.latitude, store.longitude);
                            }), origin, promises).then(function () {
                                $.when.apply($, promises).then(function () {
                                    var allValues = [];
                                    //If we go over rate limit we need to drop the values which will be undefined
                                    for (var i = 0; i < arguments.length; i++) {
                                        for (var j = 0; j < arguments[i].length; j++) {
                                            if (!arguments[i][j]) {
                                                break;
                                            }
                                            for (var k = 0; k < arguments[i][j].length; k++) {
                                                allValues.push(arguments[i][j][k]);
                                            }
                                        }
                                    }
                                    console.log("All values size " + allValues.length);
                                    $('.loading').hide();
                                    var numberOfStoresFound = 0;
                                    allValues.filter(function (store) {
                                        return store !== null && store !== undefined;
                                    }).sort(function (storeA, storeB) { //Sort the stores in ascending order with the lowest time being first
                                        return storeA.distanceInTime - storeB.distanceInTime;
                                    }).forEach(function (store) {
                                        numberOfStoresFound++;
                                        $('#stores_modal').on('shown.bs.modal', function (e) {
                                            $(this).find('.modal-body ul').append(PG_Call.listStoresHTML(store, store.distanceInTime < Number.MAX_VALUE));
                                            PG_Call.startCall();
                                        });
                                        $('#stores_modal').on('hidden.bs.modal', function (e) {
                                            $(this).find('.modal-body ul').html('');
                                        });
                                    });
                                    if (numberOfStoresFound > 0) {
                                        $('#stores_modal').modal('show');
                                    }
                                    console.log("Number of stores found [" + numberOfStoresFound + "]");
                                });
                            });
                        }
                    });
                }, function (failure) {
                    $('.loading').hide();
                    alert("Cannot service your request without geolocation data. \n" + failure.message);
                });
            } else {
                alert('Cannot service your request without geolocation data.');
            }
        },

        listStores: function () {
            var list = '';
            var nearby_stores = JSON.parse(readCookie('nearby_stores'));
            handleErrors(nearby_stores, 'err');
            if (nearby_stores) {
                nearby_stores.forEach(function (store) {
                    list += PG_Call.listStoresHTML(store);
                });
            }
            $('#stores').html(list);
        },

        listStoresHTML: function (store, couldFindRouteToStore) {

            var alertHtml = "";
            if (!couldFindRouteToStore) {
                alertHtml = '<div class="alert alert-danger" role="alert"><span class="danger-icon glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;&nbsp; Could not find a route to this store, sorted to the bottom of the list.</div>'
            }

            return '<li class="no-route">' +
                '	<form method="post" action="">' +
                '		<button type="submit" class="btn btn-md btn-default pull-right"><strong>Start Call</strong> <span class="glyphicon glyphicon-chevron-right"></span></button>' +
                '		<h4 class="store_title">' +
                '			<strong>' + store.store + ' (#' + store.store_number + ')</strong> <br>' +
                '		</h4> ' +
                '		<span class="store_location">' +
                '			' + store.store_address + '<br class="visible-xs">' +
                '			' + store.store_city + ', ' + store.store_st + ' <br class="visible-xs">' +
                '			' + store.store_zip +
                '		</span>' + alertHtml +
                '		<input type="hidden" name="store_id" value="' + store.id + '" />' +
                '	</form><hr>' +
                '</li>';
        },

        startCall: function () {
            $('#stores_modal .modal-dialog .modal-body ul li').each(function () {
                var store_id = $(this).find('input[name=store_id]').val();
                $(this).find('button[type=submit]').on('click', function (e) {
                    e.preventDefault();
                    createCookie('store_id', store_id); // Store the store ID for the call
                    location.href = '/user/call/previous-notes';
                });
            });
        }
    };
    PG_Call.init();
}($));