(function ($) {
    window.PG_ProductMix = {

        orderby: 'name',
        direction: 'asc',

        init: function () {
            switch (location.pathname) {
                case "/user/call/products":
                    PG_ProductMix.getProductMix(PG_ProductMix.orderby, PG_ProductMix.direction);
                    break;
            }
        },

        getProductMix: function (orderby, direction) {
            var store_id = readCookie('store_id');
            var url = location.href + '/get/' + store_id + '/' + orderby + '/' + direction;
            AJAXGetRequest(url, function (productmix) {
                if (productmix) {
                    PG_ProductMix.listProductMix(productmix);
                    PG_ProductMix.addProductMix();
                }
            });
        },

        listProductMix: function (productmix) {
            var list = '';
            var titles = '';
            var fieldTitles = {
                sku: 'SKU',
                retailer: 'Retailer',
                name: 'Product'
            };
            titles += '<th><a class="checkAll" href="javascript:void(0);"><span class="glyphicon glyphicon-ok"></span></a></th>';
            for (var key in fieldTitles) {
                if (fieldTitles.hasOwnProperty(key)) {
                    titles += '<th><a href="javascript:void(0);" onclick="PG_ProductMix.orderBy(\'' + key + '\',\'' + PG_ProductMix.direction + '\')">' + fieldTitles[key] + '<span class="caret"></span></a></th>';
                }
            }
            productmix.forEach(function (product, index) {
                if (product.inv === 1) {
                    list += PG_ProductMix.listProductMixHTML(product.product, index, true);
                } else {
                    var product_mix = readCookie('product_mix');
                    if (product_mix && product_mix.indexOf(product.product.id) != -1) {
                        list += PG_ProductMix.listProductMixHTML(product.product, index, true);
                    }
                }
            });
            $('table').html('<thead>' + titles + '</thead><tbody>' + list + '</tbody');
        },

        listProductMixHTML: function (product, index, checked=false) {
            return '<tr>' +
                '	<td><input type="checkbox" name="products[]" value="' + product.id + '" ' + ((checked === true) ? 'checked="checked"' : '') + ' /></td>' +
                '	<td>' + product.sku + '</td>' +
                '	<td>' + product.retailer + '</td>' +
                '	<td>' + product.name + '</td>' +
                '</tr>';
        },

        addProductMix: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                var product_mix = [];
                var product_mix_items_missing = [];
                var products = $('input[type=checkbox]:checked');
                var missing_products = $('input[type=checkbox]:not(:checked)');
                if (products.length > 0) {
                    products.each(function (product, item) {
                        product_mix.push($(this).val());
                    });
                    missing_products.each(function (product, item) {
                        product_mix_items_missing.push($(this).val());
                    });

                    createCookie('product_mix', product_mix);
                    createCookie('product_mix_items_missing', product_mix_items_missing);
                    location.href = '/user/call/inventory';
                } else {
                    handleErrors('Select the products that are available at this location.', 'err');
                    return false;
                }
            });
        },

        orderBy: function (field, direction) {
            PG_ProductMix.getProductMix(field, direction);
            PG_ProductMix.direction = (direction === 'asc') ? 'desc' : 'asc';
        }
    };
    PG_ProductMix.init();
}($));