(function ($) {
    window.PG_Notes = {

        selectedValue: 'Home Depot',

        init: function () {
            switch (location.pathname) {
                case "/user/notes":
                    $('input[type=radio]').on('click', function (event) {
                        PG_Notes.selectedValue = event.target.parentNode.textContent;
                        $('#select').select2('close');
                        $('#select').val(null).trigger('change');
                    });
                    $(document).ready(function () {
                        $('#select').select2({
                            matcher: PG_Notes.matcher,
                            allowClear: true,
                            placeholder: 'Store #',
                            templateResult: PG_Notes.formatState
                        });
                        $('#select').on('change', PG_Notes.enableOrDisableViewButton);
                        $('#select').val(null).trigger('change');
                        $('#main').show();
                    });
                    $('button').on('click', function (event) {
                        location.href = '/user/notes/' + $('#select').select2('data')[0].id + '/list';
                    });
                    break;
                case "/user/call/notes":
                    PG_Notes.hookupNotesClickLisenter();
                    break;
                case "/user/call/tpg":
                    PG_Notes.hookupTpgClickLisenter();
                    break;
                case "/user/notes/remote":
                    $('input[type=radio]').on('click', function (event) {
                        PG_Notes.selectedValue = event.target.parentNode.textContent;
                        $('#select').select2('close');
                        $('#select').val(null).trigger('change');
                    });
                    $('textarea').on('keyup', this.enableOrDisableCreationButton);
                    $(document).ready(function () {
                        $('#select').select2({
                            matcher: PG_Notes.matcher,
                            allowClear: true,
                            placeholder: 'Store #',
                            templateResult: PG_Notes.formatState
                        });
                        $('#select').on('change', PG_Notes.enableOrDisableCreationButton);
                        $('#select').val(null).trigger('change');
                        $('#main').show();
                    });
                    $('button').on('click', function (event) {
                        event.preventDefault();
                        $.ajax({
                            url: '/user/notes/remote',
                            type: 'POST',
                            data: {storeId: $('#select').select2('data')[0].id, notesText: $('#notes').val()},
                            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
                        }).done(function (data) {
                            $('.alert-success').show().delay(1000).fadeOut('slow', function() {$(this).hide();});
                            $('#select').val(null).trigger('change');
                            $('textarea').val(null);
                        }).fail(function (data) {
                            $('.alert-danger').show().delay(1000).fadeOut('slow', function() {$(this).hide();});
                        });
                    });
                    break;
            }
        },

        formatState: function formatState(state) {
            if (!state.id) {
                return state.text;
            }

            if (state.element.getAttribute('data-storetype') !== PG_Notes.selectedValue) {
                return null;
            }

            var baseUrl = "/user/pages/images/flags";
            var $state = $(
                '<span>' + state.text + '</span>'
            );
            return $state;
        },

        matcher: function (params, data) {
            // If there are no search terms, return all of the data
            if ($.trim(params.term) === '') {
                return data;
            }

            // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            if (data.element.getAttribute('data-storetype') !== PG_Notes.selectedValue) {
                return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
                var modifiedData = $.extend({}, data, true);
                modifiedData.text += ' (matched)';

                // You can return modified objects from here
                // This includes matching the `children` how you want in nested data sets
                return modifiedData;
            }

            // Return `null` if the term should not be displayed
            return null;
        },

        hookupNotesClickLisenter: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                var notes = $('textarea[name="notes"]').val();
                if (notes.length > 0) {
                    createCookie('notes', notes);
                    location.href = '/user/call/tpg';
                } else {
                    createCookie('notes', '');
                    location.href = '/user/call/tpg';
                }
            });
        },

        hookupTpgClickLisenter: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                var notes = $('textarea[name="notes"]').val();
                createCookie('tpg', notes);
                location.href = '/user/call/field-observations';
            });
        },

        enableOrDisableCreationButton: function () {
            if ($('#select').select2('data').length > 0 && $('#notes').val().length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        },
        enableOrDisableViewButton: function () {
            if ($('#select').select2('data').length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        }

    };
    PG_Notes.init();
}($));