function GoogleGeocoder(type, location, callback=function () {
}) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location': location}, callback);
}

var totalRequestCount = 0;

function GoogleDistanceMatrix(origin, destinations, callback=function () {
}) {
    var deferred = $.Deferred();
    // Make call to Google Distance Matrix API
    var service = new google.maps.DistanceMatrixService();
    totalRequestCount++;
    service.getDistanceMatrix({
        origins: origin, // Current location
        destinations: destinations, // Target destinations
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false
    }, function (response, status) {
        if (response === null) {
            response = {};
        }
        if (status === google.maps.DistanceMatrixStatus.OVER_QUERY_LIMIT) {
            // handleErrors("You're over your query limit. Either you have to pay for more queries per day, or wait until some time passes.", 'err');
        }
        response.distanceMatrixStatus = status;
        deferred.resolve(response);
    });
    return deferred.promise();
}


function handleErrors(msg, type) {
    $('.alert').remove();
    var message;
    switch (type) {
        case "msg":
            message = '<div class="alert alert-success" role="alert">' +
                '		<span class="success-icon glyphicon glyphicon-ok-sign" aria-hidden="true"></span>&nbsp;&nbsp;' + msg +
                '</div>';
            break;
        case "err":
            message = '<div class="alert alert-danger" role="alert">' +
                '		<span class="danger-icon glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;&nbsp;' + msg +
                '</div>';
            break;
    }
    $(message).insertBefore('form').delay(5000).fadeOut('slow');
    $(window).scrollTop(0, 0);
}


function AJAXRequest(url, data, callback=function () {
}) {
    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
    });
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: function (data) {
            $('.alert').remove();
            var message = '<div class="alert alert-success" role="alert">' +
                '		<span class="success-icon glyphicon glyphicon-ok-sign" aria-hidden="true"></span>&nbsp;&nbsp;' + data.success +
                '</div>';
            $(message).insertBefore('form');
            callback(data);
            console.log(data.success);
        },
        error: function (data) {
            $('.alert').remove();
            var error = '<div class="alert alert-danger" role="alert">' +
                '		<span class="danger-icon glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span><br>STATUS TEXT:' + data.statusText + '<br>STATUS CODE: ' + data.status + '<br>RESPONSE TEXT: ' + data.responseText + '<br>DATE AND TIME: ' + new Date().toString() +
                '</div>';
            $(error).insertBefore('form').html();
            console.log(data.error);
        }
    });
}


function AJAXPutRequest(url, data, callback=function () {
}) {
    $.ajaxSetup({
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
    });
    $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: function (data) {
            callback(data);
            console.log(data);
        },
        error: function (response) {
            console.log(response);
            handleErrors(response, 'err');
        }
    });
}


function AJAXGetRequest(url, callback=function () {
}) {
    setTimeout(function () {
        $.ajaxSetup({
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')}
        });
        $.ajax({
            url: url,
            type: 'GET',
            contentType: 'application/json',
            success: function (data) {
                console.log(data);
                callback(data);
            },
            error: function (response) {
                console.log(response);
                AJAXGetRequest(url, callback);
            }
        });
    }, 200);
}


function createCookie(name, value, days) {
    var options = {
        path: '/'
    };
    if (days) {
        options.expires = days
    }
    Cookies.set(name, value, options);
}

function readCookie(name) {
    return Cookies.get(name, {path: '/'});
}

function eraseCookie(name) {
    Cookies.remove(name);
}
