(function ($) {
    window.PG_Signage = {

        init: function () {
            switch (location.pathname) {
                case "/user/call/signage":
                    PG_Signage.hookupListeners();
                    break;
            }
        },

        hookupListeners: function () {
            var reminderText = $('#reminderText');
            if ($('input[type=checkbox]:checked').length > 0) {
                reminderText.removeAttr('hidden');
            } else {
                reminderText.attr('hidden', 'hidden');
            }

            $('input[type=checkbox]').on('click', function() {
                if ($('input[type=checkbox]:checked').length > 0) {
                    reminderText.removeAttr('hidden');
                } else {
                    reminderText.attr('hidden', 'hidden');
                }
            });
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('signage', {
                    areDrywallFlagsMissing: $('#areDrywallFlagsMissing').prop('checked'),
                    arePermaBaseBungeesMissing: $('#arePermaBaseBungeesMissing').prop('checked')
                });
                location.href = '/user/call/contacts';
            });
        }

    };
    PG_Signage.init();
}($));