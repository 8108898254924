(function ($) {
    window.PG_Field_Observations = {
        init: function () {
            switch (location.pathname) {
                case "/user/call/field-observations":
                    PG_Field_Observations.connectUpload();
                    PG_Field_Observations.registerNextHandler();
                    break;
            }
        },

        connectUpload: function() {
            var uploader = new qq.FineUploader({
                element: document.getElementById("myFineUploader"),
                callbacks: {
                    onComplete : function (id, name, responseJSON) {
                        var cookie = readCookie("imageIds");
                        if (cookie) {
                            var previousImageIds = JSON.parse(cookie);
                            previousImageIds.push(responseJSON.imageId);
                            createCookie("imageIds", previousImageIds);
                        } else {
                            createCookie("imageIds", [responseJSON.imageId]);
                        }
                    }
                }
                // ,
                // deleteFile: {
                //     enabled: true
                // }
            });
            uploader.setCustomHeaders({"X-CSRF-TOKEN" : document.getElementById("token").value});
            uploader.setEndpoint("/user/images");

            if (qq.ios()) {
                qq(document.getElementById("cameraButtonContainer")).addClass('ios');

                qq(document.getElementById("cameraButton")).attach("change", function() {
                    uploader.addFiles(this);
                });
            }
        },

        registerNextHandler: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('imageNotes', $('textarea[name="notes"]').val());
                location.href = '/user/call/end';
            });
        }

    };
    PG_Field_Observations.init();
}($));