(function($){
	window.PG_AdminStores = {

		orderby: 'created_at',
		direction: 'asc',

		init: function(){
			if(location.pathname === '/admin/stores'){
				PG_AdminStores.getStores(PG_AdminStores.orderby,PG_AdminStores.direction);
			}else if(location.pathname.indexOf('/admin/stores/products') != -1){
				PG_AdminStores.getStoreProducts(PG_AdminStores.orderby,PG_AdminStores.direction);
			}else if(location.pathname == '/admin/stores/add' || location.pathname.indexOf('/admin/stores/edit') != -1){
				PG_AdminStores.SetStoreLatLng();
			}
		},

		getStores: function(orderby, direction){
			var url = location.href + '/get/' +orderby+ '/' +direction;
			AJAXGetRequest(url,function(stores){
				if(stores){
					PG_AdminStores.listStores(stores);
				}
			});
		},

		getStoreProducts: function(orderby,direction){
			var url = location.href + '/get/' +orderby+ '/' +direction;
			AJAXGetRequest(url,function(products){
				if(products){
					PG_AdminStores.listStoreProducts(products);
				}
			});
		},

		listStores: function(stores){
			var list = '';
			var titles = '';
			var fieldTitles = {
				store_number: 'No.',
				store: 'Store',
				store_address: 'Address',
				store_city: 'City',
				store_st: 'St.',
				store_zip: 'Zip'
			};
			for(var key in fieldTitles){
				if(fieldTitles.hasOwnProperty(key)){
					titles += '<th><a href="javascript:void(0);" onclick="PG_AdminStores.orderStoresBy(\'' +key+ '\',\'' +PG_AdminStores.direction+ '\')">' +fieldTitles[key]+ '<span class="caret"></span></a></th>';
				}
			}
			titles += '<th></th>'
			stores.forEach(function(store,index){
				list += PG_AdminStores.listStoreHTML(store,index);
			});
			$('table').html('<thead>' +titles+ '</thead><tbody>' +list+ '</tbody');
		},

		listStoreProducts: function(products){
			var list = '';
			var titles = '<th><input type="checkbox" name="checkAll" onclick="PG_AdminStores.toggleCheckboxes($(this));" checked="" /></th>';
			var fieldTitles = {
				sku: 'SKU',
				retailer: 'Retailer',
				name: 'Product',
			};
			for(var key in fieldTitles){
				if(fieldTitles.hasOwnProperty(key)){
					titles += '<th><a href="javascript:void(0);" onclick="PG_AdminStores.orderProductsBy(\'' +key+ '\',\'' +PG_AdminStores.direction+ '\')">' +fieldTitles[key]+ '<span class="caret"></span></a></th>';
				}
			}
			products['products'].forEach(function(product,index){
				if(products['store_products'].indexOf(product.id) != -1){
					list += PG_AdminStores.listStoreProductHTML(product,index,true);
				}else{
					list += PG_AdminStores.listStoreProductHTML(product,index);
				}
			});
			$('.table').html('<thead>' +titles+ '</thead><tbody>' +list+ '</tbody');
		},

		listStoreHTML: function(store,index){
			return  '<tr>' + 
					'	<td>' +store.store_number+ '</td>' + 
					'	<td><strong>'  +store.store+ '</strong></td>' + 
					'	<td>'  +store.store_address+ '</td>' + 
					'	<td>'  +store.store_city+ '</td>' + 
					'	<td class="text-center">'  +store.store_st+ '</td>' + 
					'	<td>'  +store.store_zip+ '</td>' + 
					'	<td>&nbsp;</td>' +
					'	<td class="text-center">' + 
					'		<a href="/admin/stores/edit/' +store.id+ '" title="Edit Stores"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>' +
					'		<a href="/admin/stores/products/' +store.id+ '" title="Store Products"><span class="glyphicon glyphicon-shopping-cart" aria-hidden="true"></span></a>' +
					'		<a href="javascript:void(0);" onclick="PG_AdminStores.delStore(\'' +store.id+ '\')" title="Delete Store"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a>' +
					'	</td>' +
					'</tr>';
		},

		listStoreProductHTML: function(product,index,checked=false){
			return  '<tr>' + 
					'	<td><input type="checkbox" name="products[]"  id="product' +product.id+ '" value="' +product.id+ '" ' +((checked === true) ? 'checked="checked"' : '' )+ ' /></td>' + 
					'	<td>' +product.sku+ '</td>' + 
					'	<td>' +product.retailer+ '</td>' + 
					'	<td>' +product.name+ '</td>' + 
					'	<td class="text-right">' + 
					'		<a href="/admin/products/edit/' +product.id+ '" title="Edit Stores"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>' + 
					'		<a href="javascript:void(0);" onclick="PG_AdminProducts.delProduct(\'' +product.id+ '\')" title="Delete Product"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a>' + 
					'	</td>' + 
					'</tr>';
		},

		orderStoresBy: function(field,direction){
			PG_AdminStores.getStores(field,direction);
			PG_AdminStores.direction = (direction == 'asc') ? 'desc' : 'asc';
		},

		orderProductsBy: function(field,direction){
			PG_AdminStores.getStoreProducts(field,direction);
			PG_AdminStores.direction = (direction == 'asc') ? 'desc' : 'asc';
		},

		toggleCheckboxes:  function(trigger){
			var checkboxes = $('table tbody tr td input[type=checkbox]');
			trigger.on('click',function(e){
				e.preventDefault();
				if($(this).prop('checked') === false){
					$(this).prop('checked',true);
					checkboxes.prop('checked',true);
				}else{
					$(this).prop('checked',false);
					checkboxes.prop('checked',false);
				}
			});
		},

		delStore: function(id){
			var url = location.href+'/del/'+id;
			AJAXRequest(url,{ id:id },function(){
				PG_AdminStores.getStores(PG_AdminStores.orderby,PG_AdminStores.direction);
			});
		},

		SetStoreLatLng: function() {

			$('#coordinates').on('click',function(){

				var fields = [], values = [], parameters, address, city, st, zip;
				var form = $('.page-admin-stores-add form,.page-admin-stores-edit form');
			 	var inputs = form.find('input,select[name="store_st"]');

				inputs.each(function(){
					var val = $(this).val();
					var name = $(this).attr('name');
					if(val.length > 0){
						if(values.length < 5){
							switch(name){
								case "store_address": 	address = (val !== address) ? val : ''; break;
								case "store_city": 		city = (val !== city) ? val : ''; break;
								case "store_st": 		st = (val !== st) ? val : ''; break;
								case "store_zip": 		zip = (val !== zip) ? val : ''; break;
							}
							values.push(val.replace(' ','+'));
						}
					}
				});

				parameters = address +', '+ city +','+ st +' '+ zip;
				parameters = parameters.replace(/ /g,'+');

				var url = "https://maps.googleapis.com/maps/api/geocode/json?address="+parameters+"&key=AIzaSyBWwdwccYOTlw8oOoCo7McDzsxbjjzIlq0";
				$.ajax({
				    url: url,
				    traditional: true,
				    success: function(data){
				   		if(data){
				   			console.log(data.results[0]);
							form.find("input[name=latitude]").val(data.results[0].geometry.location.lat);
							form.find("input[name=longitude]").val(data.results[0].geometry.location.lng);
							form.find("input[name=formatted_address]").val(data.results[0].formatted_address);
							form.find("input[name=place_id]").val(data.results[0].place_id);
						}
				    },
				    error: function(data){
				   		console.log(data);
				    }
				});
			});
		}
	}
	PG_AdminStores.init();
}($));