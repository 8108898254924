(function ($) {
    window.PG_Contacts = {

        init: function () {
            switch (location.pathname) {
                case "/user/call/contacts":
                    PG_Contacts.hookUpClickListener();
                    break;
            }
        },

        hookUpClickListener: function () {
            $('input[type=checkbox]').on('change', this.enableOrDisableNext);
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('contacts', {
                    wasProspecialistChecked: $('#proSpecialist').prop('checked'),
                    wasASMChecked: $('#ASM').prop('checked'),
                    wasDepartmentManagerChecked: $('#departmentManager').prop('checked'),
                    wasOtherChecked: $('#other').prop('checked')
                });
                location.href = '/user/call/products/knowledge';
            });
        },

        enableOrDisableNext: function () {
            if ($('input[type=checkbox]:checked').length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        }
    };
    PG_Contacts.init();
}($));