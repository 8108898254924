(function ($) {
    window.PG_AdminExport = {
        selectedValue: 'Both',

        init: function () {
            if (location.pathname === '/admin/export') {
                var select = $('#select');
                $('input[type=radio]').on('click', function (event) {
                    PG_AdminExport.selectedValue = event.target.parentNode.textContent;
                    select.select2('close');
                    select.val(null).trigger('change');
                });
                $(document).ready(function () {
                    select.select2({
                        matcher: PG_AdminExport.matcher,
                        allowClear: true,
                        placeholder: 'Store # (Leave blank for all stores)',
                        templateResult: PG_AdminExport.formatState
                    });
                    select.val(null).trigger('change');
                    $('#datepicker').datepicker({
                        todayBtn: "linked",
                        keepEmptyValues: true,
                        endDate: "0d",
                        startDate: "7/3/2016",
                        clearBtn: true,
                        todayHighlight: true
                    });
                    $('#main').show();
                });
                // $('button').on('click', function (event) {
                //
                // });
            }
        },

        formatState: function formatState(state) {
            if (!state.id) {
                return state.text;
            }

            if (state.element.getAttribute('data-storetype') !== PG_AdminExport.selectedValue && PG_AdminExport.selectedValue !== 'Both') {
                return null;
            }

            return $(
                '<span>' + state.text + '</span>'
            );
        },

        matcher: function (params, data) {
            // If there are no search terms, return all of the data
            if ($.trim(params.term) === '') {
                return data;
            }

            // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            if (data.element.getAttribute('data-storetype') !== PG_AdminExport.selectedValue && PG_AdminExport.selectedValue !== 'Both') {
                return null;
            }

            // `params.term` should be the term that is used for searching
            // `data.text` is the text that is displayed for the data object
            if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) > -1) {
                var modifiedData = $.extend({}, data, true);
                modifiedData.text += ' (matched)';

                // You can return modified objects from here
                // This includes matching the `children` how you want in nested data sets
                return modifiedData;
            }

            // Return `null` if the term should not be displayed
            return null;
        }
    };
    PG_AdminExport.init();
}($));