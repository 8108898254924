(function ($) {
    window.PG_Inventory = {
        init: function () {
            switch (location.pathname) {
                case "/user/call/inventory":
                    PG_Inventory.initializeInventoryPage();
                    break;
                case "/user/call/inventory-action":
                    PG_Inventory.initializeInventoryActionPage();
                    break;
            }
        },

        initializeInventoryPage: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                var isThereInventoryIssueChecked = $('#isThereInventoryIssue').prop('checked');
                if (isThereInventoryIssueChecked) {
                    location.href = '/user/call/inventory-action';
                } else {
                    createCookie('inventory_action', {});
                    location.href = '/user/call/shelflife';
                }
            });
        },

        initializeInventoryActionPage: function () {
            $('input[type=checkbox]').on('change', this.enableOrDisableNext);
            $('textarea').on('keyup', this.enableOrDisableNext);
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('inventory_action', {
                    wasMovedToShelfChecked: $('#movedToShelfCheckbox').prop('checked'),
                    wasProductOnOrderChecked: $('#productOnOrderCheckbox').prop('checked'),
                    wasUnableToResolveIssue: $('#unableToResolveIssue').prop('checked'),
                    problemText: $('#problemText').val()
                });
                location.href = '/user/call/shelflife';
            });
        },

        enableOrDisableNext: function () {
            if ($('input[type=checkbox]:checked').length > 0 && $('#problemText').val().length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        }
    };

    PG_Inventory.init();
}($));