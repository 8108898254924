(function ($) {
    window.PG_End = {

        details: [
            'caller_lat',
            'caller_lng',
            'store_id',
            'product_mix',
            'inventory_action',
            'shelflife_action',
            'signage',
            'contacts',
            'notes',
            'tpg',
            'product_mix_items_missing',
            'imageIds',
            'imageNotes',
            'business_unit_name',
            'name_of_person_PKed'
        ],

        theCall: {},

        init: function () {
            switch (location.pathname) {
                case "/user/call/end":
                    PG_End.getTheCall();
                    break;
            }
        },

        getTheCall: function () {
            PG_End.details.forEach(function (part) {
                PG_End.theCall[part] = readCookie(part);
            });
            PG_End.makeTheCall();
        },

        makeTheCall: function () {
            $('button[type=submit]').on('click', function (e) {
                $('button[type=submit]').attr('disabled', 'disabled');
                e.preventDefault();
                if (PG_End.theCall) {
                    AJAXRequest(location.href, PG_End.theCall, function (response) {
                        if (response.success) {
                            PG_End.clearTheCall();
                            location.href = '/user?msg=' + response.message;
                        } else {
                            $('button[type=submit]').removeAttr('disabled');
                        }
                    });
                } else {
                    $('button[type=submit]').removeAttr('disabled');
                    handleErrors('There was an error', 'err');
                    return false;
                }
            });
        },

        clearTheCall: function () {
            PG_End.details.forEach(function (part) {
                eraseCookie(part);
            });
        }
    };
    PG_End.init();
}($));