(function ($) {
    window.PG_Shelflife = {

        orderby: 'name',
        direction: 'asc',

        init: function () {
            switch (location.pathname) {
                case "/user/call/shelflife":
                    PG_Shelflife.initializeShelfLifePage();
                    break;
                case "/user/call/shelflife-action":
                    PG_Shelflife.initializeShelfLifeActionPage();
                    break;
            }
        },

        initializeShelfLifePage: function () {
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                if ($('input[type=checkbox]:checked').length > 0) {
                    location.href = '/user/call/shelflife-action';
                } else {
                    createCookie('shelflife_action', {});
                    location.href = '/user/call/signage';
                }
            });
        },

        initializeShelfLifeActionPage: function () {
            $('input[type=checkbox]').on('change', this.enableOrDisableNext);
            $('textarea').on('keyup', this.enableOrDisableNext);
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('shelflife_action', {
                    wasNotifiedManagerChecked: $('#notifiedManager').prop('checked'),
                    wasUnableToResolveIssue: $('#unableToResolveIssue').prop('checked'),
                    problemText: $('#problemText').val()
                });
                location.href = '/user/call/signage';
            });
        },

        enableOrDisableNext: function () {
            if ($('input[type=checkbox]:checked').length > 0 && $('#problemText').val().length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        }

    };
    PG_Shelflife.init();
}($));