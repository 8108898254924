(function ($) {
    window.PG_ProductKnowledge = {

        init: function () {
            switch (location.pathname) {
                case "/user/call/products/knowledge":
                    PG_ProductKnowledge.hookupListeners();
                    this.enableOrDisableNext();
                    break;
            }
        },

        hookupListeners: function () {
            $('#nameOfPersonPKed').on('change', this.enableOrDisableNext);
            $('button[type=submit]').on('click', function (e) {
                e.preventDefault();
                createCookie('business_unit_name', $('select').find(':selected').map(function(index, domElement) {return domElement.value}).toArray()[0]);
                createCookie('name_of_person_PKed', $('input[name="nameOfPersonPKed"]').val());
                location.href = '/user/call/notes';
            });
        },

        enableOrDisableNext: function () {
            if ($('#nameOfPersonPKed').val().length > 0) {
                $('button[type=submit]').removeAttr('disabled');
            } else {
                $('button[type=submit]').attr('disabled', 'disabled');
            }
        }

    };
    PG_ProductKnowledge.init();
}($));