(function($){
	window.PG_AdminUsers = {

		orderby: 'lastname',
		direction: 'asc',

		init: function(){
			if(location.pathname === '/admin/users'){
				PG_AdminUsers.getUsers(PG_AdminUsers.orderby,PG_AdminUsers.direction);
			}
		},

		getUsers: function(orderby,direction){
			var url = location.href + '/get/' +orderby+ '/' +direction;
			AJAXGetRequest(url,function(users){
				if(users){
					PG_AdminUsers.listUsers(users);
				}
			});
		},

		listUsers: function(users){
			var list = '';
			var titles = '';
			var fieldTitles = {
				type: 'Type',
				firstname: 'First name',
				lastname: 'Last name',
				email: 'Email'
			};
			for(var key in fieldTitles){
				if(fieldTitles.hasOwnProperty(key)){
					titles += '<th><a href="javascript:void(0);" onclick="PG_AdminUsers.orderBy(\'' +key+ '\',\'' +PG_AdminUsers.direction+ '\')">' +fieldTitles[key]+ '<span class="caret"></span></a></th>';
				}
			}
			titles += '<th></th>'
			users.forEach(function(user,index){
				list += PG_AdminUsers.listUserHTML(user,index);
			});
			$('table').html('<thead>' +titles+ '</thead><tbody>' +list+ '</tbody');
		},

		listUserHTML: function(user,index){
			return  '<tr>' + 
					'	<td>' +PG_AdminUsers.getType(user.type)+ '</td>' + 
					'	<td>' +user.firstname+ '</td>' + 
					'	<td>' +user.lastname+ '</td>' + 
					'	<td><a href="mailto:' +user.email+ '">' +user.email+ '</a></td>' + 
					'	<td class="text-right">' + 
					'		<a href="/admin/users/edit/' +user.id+ '"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>' + 
					'		<a href="javascript:void(0);" onclick="PG_AdminUsers.delUser(\'' +user.id+ '\')"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a>' + 
					'	</td>' + 
					'</tr>';
		},

		getType: function(type){
			return (type === 'admin') ? 'Admin' : 'Caller';
		},

		orderBy: function(field,direction){
			PG_AdminUsers.getUsers(field,direction);
			PG_AdminUsers.direction = (direction == 'asc') ? 'desc' : 'asc';
		},

		delUser: function(id){
			var url = location.href+'/del/' +id;
			AJAXRequest(url,{},function(){
				PG_AdminUsers.getUsers();
			});
		},
	}
	PG_AdminUsers.init();
}($));