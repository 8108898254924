(function($){
	window.PG_AdminProducts = {

		orderby: 'name',
		direction: 'asc',

		init: function(){
			if(location.pathname === '/admin/products'){
				PG_AdminProducts.getProducts(PG_AdminProducts.orderby,PG_AdminProducts.direction);
			}
		},

		getProducts: function(orderby,direction){
			var url = location.href + '/get/' +orderby+ '/' +direction;
			AJAXGetRequest(url,function(products){
				if(products){
					PG_AdminProducts.listProducts(products);
				}
			});
		},

		listProducts: function(products){
			var list = '';
			var titles = '';
			var fieldTitles = {
				id: 'No.',
				sku: 'SKU',
				retailer: 'Retailer',
				name: 'Product'
			};
			for(var key in fieldTitles){
				if(fieldTitles.hasOwnProperty(key)){
					titles += '<th><a href="javascript:void(0);" onclick="PG_AdminProducts.orderBy(\'' +key+ '\',\'' +PG_AdminProducts.direction+ '\')">' +fieldTitles[key]+ '<span class="caret"></span></a></th>';
				}
			}
			titles += '<th></th>';
			products.forEach(function(product,index){
				list += PG_AdminProducts.listProductHTML(product,index);
			});
			$('table').html('<thead>' +titles+ '</thead><tbody>' +list+ '</tbody');
		},

		listProductHTML: function(product){
			return  '<tr>' + 
					'	<td>' +product.id+ '</td>' + 
					'	<td>' +product.sku+ '</td>' + 
					'	<td>' +product.retailer+ '</td>' + 
					'	<td>' +product.name+ '</td>' + 
					'	<td class="text-right">' + 
					'		<a href="/admin/products/edit/' +product.id+ '"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a>' + 
					'		<a href="javascript:void(0);" onclick="PG_AdminProducts.delProduct(' +product.id+ ')"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a>' + 
					'	</td>' + 
					'</tr>';
		},

		orderBy: function(field,direction){
			PG_AdminProducts.getProducts(field,direction);
			PG_AdminProducts.direction = (direction === 'asc') ? 'desc' : 'asc';
		},

		delProduct: function(id){
			var url = location.href+'/del/'+id;
			AJAXRequest(url,{ id:id },function(){
				PG_AdminProducts.getProducts(PG_AdminProducts.orderby,PG_AdminProducts.direction);
			});
		}
	};
	PG_AdminProducts.init();
}($));